import 'styles/_base.scss';
import cs from 'vendor/react-arsenal/cs';

import styles from './styles.scss';

function App() {
    return (
        <main>
            <div className={styles.websiteLinkWrapper}>
                <a href='https://www.internal-displacement.org/' title='IDMC' className={styles.websiteLink}>
                    Back to main website
                </a>
            </div>
            <section className={styles.topSection}>
                <div className={styles.overlay}>
                    <div className={styles.topContentWrapper}>
                        <div className={styles.logoWrapper}>
                            <img className={styles.logo} src='https://www.internal-displacement.org/sites/default/files/IDMC_white_logo.png' alt='idmc' />
                        </div>
                        <h1 className={styles.heading1}>2023 GRID Launch Event</h1>
                        <h2 className={styles.heading2}>11 May 2023</h2>
                        <h2 className={styles.heading2}>Geneva, Switzerland</h2>
                        <p className={styles.info}>
                            Join us for the launch of our 2023 Global Report on Internal Displacement,
                            at 14:00-16:00 (CET) on <b>Thursday 11 May</b> at the Varembé Conference Centre (CCV) in
                            Geneva, Switzerland or <b>online</b>.
                        </p>
                        <web-component-embed id="9oyYQo" app_slug="countdown-timer"></web-component-embed>
                        <div className={styles.topButtonWrapper}>
                            <a className={styles.registerHereButton} href='https://www.powr.io/form-builder/i/35509283#page'>
                                Register here
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.bottomSection}>
                <div className={styles.bottomContentWrapper}>
                    <p className={styles.text}>
                        IDMC's Global Report on Internal Displacement (GRID) is the world’s most trusted
                        source of data and analysis on the risk, scale and impacts of internal displacement.
                        The 2023 report will be its eighth edition and will focus on food insecurity as a
                        driver, impact and potential barrier to solutions for internal displacement.
                    </p>
                    <p className={cs(styles.text, styles.listHeading)}>
                        During the event, IDMC will present its figures on internal displacement triggered by
                        conflict and disasters at the global level and latest estimates on the economic impacts
                        of displacement. We will also welcome a panel of experts to discuss the relationship
                        between food security and internal displacement in efforts to identify development
                        solutions for IDPs across the world.
                    </p>
                    <div className={styles.infoBox}>
                        <h3 className={styles.listTitle}>HYBRID EVENT</h3>
                        <table className={styles.eventList}>
                            <tr>
                                <td className={styles.keyWrapper}>Date:</td>
                                <td>11 May 2023</td>
                            </tr>
                            <tr>
                                <td className={styles.keyWrapper}>Time:</td>
                                <td>14:00-16:00 (CET)</td>
                            </tr>
                            <tr>
                                <td className={styles.keyWrapper}>Location:</td>
                                <td>Geneva Room, Varembé Conference Centre (CCV),<br/>Rue de Varembé 9, 1202 Geneva, Switzerland</td>
                            </tr>
                            <tr>
                                <td className={styles.keyWrapper}>Online:</td>
                                <td>Zoom</td>
                            </tr>
                        </table>
                        <h3 className={styles.listTitle}>PROGRAMME</h3>
                        <ul className={styles.programmeList}>
                            <li>Introductory remarks by <b>Ms. Alexandra Bilak</b>, Director, IDMC</li>
                            <li>Keynote address - <b>H.E. Ambassador Tine Mørch Smith</b>, Permanent Representative of Norway to the United Nations in Geneva</li>
                            <li>Presentation of the report by <b>Mr. Vicente Anzellini</b>, Global and Regional Analysis Manager, IDMC</li>
                            <li>Panel discussion moderated by <b>Ms. Alexandra Bilak</b></li>
                            <li className={styles.nestedListItem}>
                                <ul className={styles.nestedList}>
                                    <li><b>Mr. Abdirahman Abdishakur</b>, Special Presidential Envoy for Drought Response, Federal Government of Somalia</li>
                                    <li><b>Mr. Rein Paulsen</b>, Director, Office of Emergencies and Resilience, Food and Agriculture Organization</li>
                                    <li><b>Ms. Wilhelmina Welsch</b>, Coordinator, Joint IDP Profiling Service</li>
                                    <li><b>Ms. Evelien Van Roemburg</b>, Programs & Influencing Director for Oxfam in Africa, Oxfam International</li>
                                </ul>
                            </li>
                            <li>Audience Q&A</li>
                            <li>Closing remarks</li>
                            <li>A <b>cocktail reception</b> will follow.</li>
                        </ul>
                    </div>
                    <div className={styles.buttonWrapper}>
                        <a className={styles.registerButton} href='https://www.powr.io/form-builder/i/35509283#page'>
                            Register here
                        </a>
                    </div>
                    <p className={styles.bottomLink}>
                        Whilst you're waiting, check out last year's report: 
                        <a className={styles.gridLink} href='https://www.internal-displacement.org/global-report/grid2022/'>GRID 2022</a>
                    </p>
                </div>
            </section>
        </main>
    );
}

export default App;
